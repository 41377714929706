/* eslint-disable @typescript-eslint/naming-convention */
// eslint-disable-next-line no-warning-comments
/* eslint-disable sort-keys-fix/sort-keys-fix */
/* eslint-disable sort-keys */
import { Api } from "../api"
import {
	AttentionSvg,
	DegeniaLogo,
	DmuLogo, EIcon,
	HandsSvg,
	IAward,
	IFooterListProps,
	ILinkListProps,
	ILinkProps,
	IListProps,
	TelephoneStrokeSvg
} from "../../src"
import { EFilterSuffix } from "@teamparallax/common-libraries/lib/query-string/enum"
import {
	ICmsDegeniaContentTypeTeamMember,
	ICmsDegeniaReadContentTypeGenericPage
} from "@teamparallax/api-gateway-rest-api"
import { IContent, Link } from "@teamparallax/react-components"
import { createStrapiFilterQueryObject } from "@teamparallax/common-libraries/lib/query-string"
import { getServerSideTranslations } from "../ssr"
export const contactProps = {
	address: {
		city: "55545 Bad Kreuzach",
		street: "Brückes 63 - 63a"
	},
	companyList: [{
		email: "info@degenia.de",
		subtitle: "Versicherungsdienst AG",
		title: "degenia"
	}, {
		email: "info@makler-union.de",
		subtitle: "Deutsche Makler Union GmbH",
		title: "DMU"
	}],
	fax: "(0671) 8400 329",
	fon: "(0671) 8400 30",
	hasDmu: true
}
interface INavigationContent {
	awards: IAward[]
	footerListProps: IFooterListProps[]
	genericTexts: IContent[]
	linkListProps: ILinkListProps[]
	teamMembers: ICmsDegeniaContentTypeTeamMember[]
}
const api = new Api()
export const retrieveInsuranceNavigation = async (): Promise<IListProps[]> => {
	const {
		data: insurances
	} = await api.cms.getAllDegeniaInsurances()
	const categoriesTmp: string[] = []
	const categories: IListProps[] = []
	for (const insurance of insurances) {
		if (!categoriesTmp.includes(insurance.category)) {
			categories.push({
				name: insurance.category,
				image: convertCategoryToEIcon(insurance.category),
				linkUrl: `/produkte?category=${insurance.category}`
			})
			categoriesTmp.push(insurance.category)
		}
	}
	return categories
}
export const convertCategoryToEIcon = (category: string): EIcon => {
	const sanitizedCategory = category.trim().toLowerCase()
	switch (sanitizedCategory) {
		default:
		case "haftpflicht":
			return EIcon.wineglass
		case "kfz":
			return EIcon.car
		case "drohnen":
		case "luftfahrthaftpflicht - private nutzung":
		case "luftfahrthaftpflicht - gewerbliche nutzung":
		case "privat + kopter profi":
			return EIcon.drone
		case "rechtsschutz":
			return EIcon.legalProtection
		case "haus & wohnung":
		case "haus & grundbesitzer":
			return EIcon.house
		case "risikoleben":
			return EIcon.people
		case "unfall":
			return EIcon.ambulance
		case "gewässerschaden":
		case "gewässerschäden":
			return EIcon.waterdrop
		case "bauherren":
			return EIcon.excavator
		case "rund ums tier":
		case "tierhalter":
			return EIcon.sickAnimal
	}
}
export const retrieveTeamMembers = async (): Promise<ICmsDegeniaContentTypeTeamMember[]> => {
	const {
		data: teamMembers
	} = await api.cms.getAllDegeniaTeamMembers()
	return teamMembers.map((teamMember) => ({
		...teamMember,
		department: teamMember.department,
		email: teamMember.email,
		fax: teamMember.fax,
		imageUrl: teamMember.imageUrl,
		name: teamMember.name,
		phone: teamMember.phone,
		priority: teamMember.priority ?? Infinity,
		resort: teamMember.division ?? "",
		role: teamMember.role,
		vCardUrl: teamMember.vcardUrl,
		vcardUrl: teamMember.vcardUrl
	}))
}
export const retrieveNavigationContent = async (
	locale?: string
): Promise<INavigationContent> => {
	const {
		data: pagesWithNavigation
	} = await api.cms.getAllDegeniaGenericPages(createStrapiFilterQueryObject({
		filter: {
			filter: EFilterSuffix.isNull,
			key: "slug",
			value: false
		}
	}))
	const insurances = await retrieveInsuranceNavigation()
	const footerCategories: Record<string, ILinkProps[]> = {
		Unternehmen: [
			{
				linkUrl: "/team",
				name: "Serviceteam"
			},
			{
				linkUrl: "/jobs",
				name: "Jobs"
			}
		],
		Partner: [
			{
				linkUrl: "/?open=login",
				name: "Login Bereich"
			},
			{
				linkUrl: "/#partner-werden",
				name: "Partner werden"
			}
		],
		Rechtliches: []
	}
	const footerListProps = sanitizeNavigationCategories(
		pagesWithNavigation.filter((page) => page.navigation.shouldAppearOnFooter),
		footerCategories
	)
	const headerCategories: Record<string, ILinkProps[]> = {
		Versicherungen: insurances,
		Kontakt: [
			{
				linkUrl: "/team",
				name: "Serviceteam"
			}
		],
		Aktuelles: [
			{
				linkUrl: "/jobs",
				name: "Jobs"
			}
		]
	}
	const linkListProps = sanitizeNavigationCategories(
		pagesWithNavigation.filter((page) => page.navigation.shouldAppearOnNavigation),
		headerCategories,
		true
	)
	/* Awards */
	const {
		data: awardsFromCsm
	} = await api.cms.getAllDegeniaAwards()
	const awards: IAward[] = awardsFromCsm.map((award) => ({
		image: award.image ?? award.imageUrl,
		title: award.title ?? award.image?.caption
	}))
	/* Header calltimes */
	const {
		data: contents
	} = await api.cms.getAllDegeniaGenericTexts()
	return {
		...await getServerSideTranslations(locale),
		teamMembers: await retrieveTeamMembers(),
		genericTexts: contents.map((content) => content),
		awards,
		footerListProps,
		linkListProps
	}
}
export const sanitizeNavigationCategories = (
	pagesWithNavigation: ICmsDegeniaReadContentTypeGenericPage[],
	categories: Record<string, ILinkProps[]>,
	isHeader = false
): IFooterListProps[] => {
	const sortedPagesWithNavigation = pagesWithNavigation
		.sort((a, b) => (a.navigation.priority ?? Infinity) - (b.navigation.priority ?? Infinity))
	for (const page of sortedPagesWithNavigation) {
		let category = page.navigation.category
		if (isHeader) {
			category = page.navigation.overwriteNavigationCategory
		}
		categories[category] = categories[category] ?? []
		categories[category].push({
			linkUrl: `/${page.slug}`,
			name: page.navigation.overwriteTitle ?? page.header.headerTitle
		})
	}
	const categoryList: IFooterListProps[] = []
	for (const category in categories) {
		categoryList.push({
			linkProps: categories[category],
			title: category
		})
	}
	return categoryList
}
export const footerListProps: IFooterListProps[] = [
	{
		linkProps: [
			{
				linkUrl: "linkUrl",
				name: "Leitblid"
			}, {
				linkUrl: "/team",
				name: "Serviceteam"
			}, {
				linkUrl: "/jobs",
				name: "Jobs"
			}, {
				linkUrl: "linkUrl",
				name: "Presse"
			}, {
				linkUrl: "linkUrl",
				name: "Bilanzen"
			}
		],
		title: "Unternehmen"
	}, {
		linkProps: [
			{
				linkUrl: "linkUrl",
				name: "Login Bereich"
			}, {
				linkUrl: "linkUrl",
				name: "Partner werden"
			}
		],
		title: "Partner"
	}, {
		linkProps: [
			{
				linkUrl: "linkUrl",
				name: "Impressum"
			}, {
				linkUrl: "linkUrl",
				name: "Datenschutz degenia"
			}, {
				linkUrl: "linkUrl",
				name: "Datenschutz DMU"
			}, {
				linkUrl: "linkUrl",
				name: "Kunden-Erstinformationen"
			}, {
				linkUrl: "linkUrl",
				name: "Online-Support"
			}
		],
		title: "Rechtliches"
	}
]
export const socialMediaProps = {
	facebookLink: "https://de-de.facebook.com/degenia",
	youtubeLink: "https://www.youtube.com/user/degeniaAG"
}
export const logoMap = {
	degenia: <DegeniaLogo/>,
	dmu: <DmuLogo/>
}
export const sideMenuProps = [{
	component: (
		<div
			style={ {
				fontFamily: "Arial",
				fontWeight: "bold"
			} }
		>
			<p
				style={ {
					fontSize: "20px"
				} }
			>
				Telefon:
			</p>
			<p
				style={ {
					fontSize: "12px",
					marginTop: "21px"
				} }
			>
				Heute von 8:00 bis 18:00 Uhr
			</p>
			<p
				style={ {
					fontSize: "34px"
				} }
			>
				0671 8400 30
			</p>
		</div>
	),
	icon: <TelephoneStrokeSvg/>
}, {
	component: (
		<div
			style={ {
				fontFamily: "Arial",
				fontWeight: "bold"
			} }
		>
			<p
				style={ {
					fontSize: "20px"
				} }
			>
				Schaden melden:
			</p>
			<p
				style={ {
					fontSize: "12px",
					marginTop: "21px"
				} }
			>
				Hier finden Sie alle Formulare zum Thema
			</p>
			<p
				style={ {
					fontSize: "34px"
				} }
			>
				<Link href="/schadenmeldung">
					Schaden melden
				</Link>
			</p>
		</div>
	),
	icon: <AttentionSvg/>
}, {
	component: (
		<div
			style={ {
				fontFamily: "Arial",
				fontWeight: "bold"
			} }
		>
			<p
				style={ {
					fontSize: "20px"
				} }
			>
				Ansprechpartner finden:
			</p>
			<p
				style={ {
					fontSize: "12px",
					marginTop: "21px"
				} }
			>
				Hier finden Sie alle Ansprechpartner zum Thema
			</p>
			<p
				style={ {
					fontSize: "34px"
				} }
			>
				<Link href="/team">
					Service Team
				</Link>
			</p>
		</div>
	),
	icon: <HandsSvg/>
}]