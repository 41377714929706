import { AxiosError } from "axios"
import { CMSApi, UserApi } from "@teamparallax/api-gateway-rest-api"
import { DegeniaConfigApi } from "@teamparallax/api-gateway-degenia-rest-api"
import { RestClient } from "@teamparallax/common-libraries"
import getConfig from "next/config"
// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const CMaxCacheTime = 60 * 1000
export class Api {
	private static readonly restClient = new RestClient(undefined, CMaxCacheTime)
	public readonly cms: CMSApi
	public readonly degeniaConfigApi: DegeniaConfigApi
	private readonly internalApiGatewayUrl: string
	constructor() {
		const {
			apiGatewayCommonUrl,
			apiGatewayUrl
		} = getConfig().publicRuntimeConfig
		this.internalApiGatewayUrl = `${apiGatewayCommonUrl}/api/v1`
		/* @TODO atm you do not need to be logged in to
		     retrieve data from cms service - this should change */
		this.cms = new CMSApi(
			{},
			`${apiGatewayCommonUrl}/api/v1`,
			Api.restClient.axiosInstance
		)
		this.degeniaConfigApi = new DegeniaConfigApi(
			{},
			`${apiGatewayUrl}/api/v1`,
			Api.restClient.axiosInstance
		)
		Api.restClient.rejectMiddleware = async (response: AxiosError): Promise<void> => {
			/* @TODO we check 400 because cms microservice
			     incorrectly responses with 400 instead of 403 */
			if (response?.response?.data.code === "403" || response?.response?.data.code === "400") {
				await Api.login(true)
			}
			else {
				throw response
			}
		}
	}
	private static async login(shouldLogin: boolean): Promise<void> {
		if (!Api.restClient.bearer || shouldLogin) {
			try {
				const {
					internalApiGatewayUrl,
					cmsUser,
					cmsPass
				} = getConfig().publicRuntimeConfig
				const userApi = new UserApi(
					{},
					internalApiGatewayUrl,
					Api.restClient.axiosInstance
				)
				const {
					data: {
						token
					}
				} = await userApi.authenticate({
					name: cmsUser,
					password: cmsPass
				})
				Api.restClient.bearer = token
			}
			catch (e) {
				// eslint-disable-next-line no-console
				console.log("Error during Login occured - probably not logged in")
				// eslint-disable-next-line no-console
				console.log(e)
			}
		}
	}
}